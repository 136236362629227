@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,
    body {
        font-family: 'Noto Sans', sans-serif !important;
    }

    body {
        margin: 0;
    }

    /* Scrollbar for WebKit-based browsers (Chrome, Safari) */
    .custom-scrollbar::-webkit-scrollbar {
        width: 4px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: #f88035; /* Active color */
        border-radius: 10px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background-color: rgba(
            248,
            128,
            53,
            0.7
        ); /* Hover effect with 70% opacity */
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background-color: rgba(
            248,
            128,
            53,
            0.3
        ); /* Non-active with 30% opacity */
        border-radius: 10px;
    }

    /* Scrollbar for Firefox */
    .custom-scrollbar {
        scrollbar-width: thin;
        scrollbar-color: #f88035 rgba(248, 128, 53, 0.3); /* Active color and non-active background */
    }

    .doctor-filter-926 {
        filter: invert(69%) sepia(17%) saturate(5741%) hue-rotate(338deg)
            brightness(102%) contrast(103%);
    }

    .doctor-filter-925 {
        filter: invert(62%) sepia(97%) saturate(1501%) hue-rotate(338deg)
            brightness(101%) contrast(100%);
    }
    .doctor-filter-720 {
        filter: invert(57%) sepia(33%) saturate(853%) hue-rotate(175deg)
            brightness(98%) contrast(89%);
    }
}
